<template>
  <v-menu
    v-model="isOpen"
    content-class="flex"
    :bottom="bottom"
    :top="top"
    :left="left"
    :right="right"
    offset-y
    transition="scale-transition"
    :close-on-content-click="false"
    max-height="95%"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">
        <v-icon class="mr-2">{{$icon('i.Templates')}}</v-icon>
        <span>{{$t('t.Templates')}}</span>
      </v-btn>
    </template>
    <v-card class="d-flex flex-column">
      <v-text-field
        flat
        clearable
        dense
        autofocus
        hide-details
        :label="$t('t.Filter')"
        solo
        v-model="templateFilter"
        :prepend-inner-icon="$icon('i.Search')"
      />
      <v-divider></v-divider>
      <v-list
        class="overflow-y"
        dense
        v-if="_templates"
      >
        <div v-if="showCategories">
          <v-list-group
            v-for="(_, idx1) in _templates"
            :key="idx1"
            no-action
            sub-group
            value="true"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <span>{{_.category}}</span>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(i, idx2) in _.items"
              :key="idx2"
              @click="selectTemplate(i)"
            >
              <v-list-item-content>
                <span>{{i.name}}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
        <div v-else-if="_templates[0]">
          <v-list-item
            v-for="(i, idx2) in _templates[0].items"
            :key="idx2"
            @click="selectTemplate(i)"
          >
            <v-list-item-content>
              <span>{{i.name}}</span>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>

export default {
  computed: {
    _templates () {
      const items = this.templates.filter(_ => {
        return this.templateFilter
          ? _.templateCategoryName.toLowerCase().includes(this.templateFilter.toLowerCase()) || _.name.toLowerCase().includes(this.templateFilter.toLowerCase())
          : true
      })

      const notCategorizedString = this.$t('t.NotCategorized')
      const groups = this.lodash(items)
        .sortBy(_ => _.templateCategoryName.length ? _.templateCategoryName.toLowerCase() : undefined)
        .groupBy(_ => _.templateCategoryName)
        .map(function (items, c) {
          return {
            category: c || notCategorizedString,
            items: items
          }
        }).value()

      return groups
    },
    showCategories () {
      return Boolean(new Set(this.templates.map(t => t.templateCategoryId)).size > 1)
    }
  },
  data () {
    return {
      templateFilter: null,
      isOpen: false
    }
  },
  methods: {
    selectTemplate (template) {
      this.$emit('template-selected', template)
      this.isOpen = false
    }
  },
  props: {
    templates: Array,
    top: Boolean,
    bottom: Boolean,
    left: Boolean,
    right: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.overflow-y
  overflow-y auto

.flex
  display flex
</style>
